<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" v-if="showBackBtn" />
    <PageHeader :title="title" />
    <v-form
      v-model="isFormValid"
      ref="agenteCta-form"
      id="agenteCta-form"
      @submit.prevent="saveAgenteCta()"
    >
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title> Datos del agente de cuenta </v-card-title>
            <v-card-text>
              <v-row>
                <!-- Codigo agente -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    v-mask="'#########'"
                    :disabled="buscarAgente"
                    label="Código"
                    v-if="buscarAgente || buscarAgenteForAfiliado"
                    v-model="codigoAgenteCta"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Boton buscar -->
                <v-col cols="12" sm="6" md="1" class="py-0">
                  <v-btn
                    color="primary"
                    :disabled="!searchAgenteBtn"
                    v-if="buscarAgenteForAfiliado"
                    @click="findAgenteCta()"
                  >
                    Buscar
                  </v-btn>
                </v-col>
                <!-- Boton buscar por nombre o dni -->
                <v-col cols="12" sm="6" md="7" class="py-0 pl-8">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        absolute
                        fab
                        small
                        color="primary"
                        :disabled="searchAgenteBtn"
                        v-if="buscarAgenteForAfiliado"
                        @click="modalBusquedaAgecta = true"
                      >
                        <v-icon>{{ searchIcon }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Buscar por otro</span>
                  </v-tooltip>
                </v-col>
                <!-- Boton limpiar formulario -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-btn
                    color="primary"
                    @click="resetForm()"
                    text
                    v-if="buscarAgenteForAfiliado"
                  >
                    Limpiar datos
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <!-- Nombre completo -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    label="Persona o razón social (*)"
                    v-model.trim="nombreCompleto"
                    autocomplete="off"
                    :rules="
                      rules.required.concat([
                        rules.maxLength(nombreCompleto, 50),
                        rules.requiredTrim(nombreCompleto)
                      ])
                    "
                  ></v-text-field>
                </v-col>
                <!-- Tipo agente -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-autocomplete
                    v-model="tipoAgenteSelected"
                    :items="tiposAgentes"
                    label="Tipo agente de cuenta (*)"
                    item-text="value"
                    item-value="id"
                    :rules="rules.required"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Numero interno -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    label="Código interno"
                    v-mask="'##########'"
                    v-model="numeroInterno"
                    autocomplete="off"
                    :rules="
                      numeroInterno == null
                        ? []
                        : [rules.maxLength(numeroInterno, 10)]
                    "
                  ></v-text-field>
                </v-col>
                <!-- Número de CUIT -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    label="CUIT"
                    @change="validarCUIL(cuit)"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    v-mask="'##-########-#'"
                    v-model="cuit"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Tipo doc -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-autocomplete
                    v-model="tipoDocSelected"
                    :items="tiposDoc"
                    label="Tipo"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Número de documento -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    label="Documento"
                    v-mask="['#.###.###', '##.###.###']"
                    v-model="docAgenteCta"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>Datos de ingresos brutos</v-card-title>
            <v-card-text>
              <v-row>
                <!-- Jurisdiccion -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-autocomplete
                    v-model="jurisdiccionSelected"
                    :items="jurisdicciones"
                    label="Jurisdicción"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Ingresos brutos -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    label="Ingresos brutos"
                    :rules="[rules.maxLength(ingBrutos, 15)]"
                    v-model="ingBrutos"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Condicion -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-autocomplete
                    v-model="condicionSelected"
                    :items="condiciones"
                    label="Condición"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Concepto -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-autocomplete
                    v-model="conceptoSelected"
                    :items="conceptos"
                    label="Concepto"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>Otros datos</v-card-title>
            <v-card-text class="pb-0">
              <v-row>
                <!-- Calle -->
                <v-col cols="12" sm="6" md="5" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    :rules="[
                      rules.maxLength(calle, 50),
                      rules.requiredTrim(calle)
                    ]"
                    dense
                    label="Calle"
                    v-model.trim="calle"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Número domicilio -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    :rules="[rules.maxLength(numDomicilio, 10)]"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    label="Número"
                    v-model="numDomicilio"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Piso -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-text-field
                    type="text"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    outlined
                    dense
                    :rules="[rules.maxLength(piso, 5)]"
                    label="Piso"
                    v-model="piso"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Dpto. -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    :rules="[rules.maxLength(departamento, 5)]"
                    label="Dpto."
                    v-model="departamento"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Localidad -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-autocomplete
                    v-model="localidadSelected"
                    :items="localidades"
                    label="Localidad (*)"
                    :rules="rules.required"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Barrio -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-autocomplete
                    v-model="barrioSelected"
                    :items="barrios"
                    label="Barrio (*)"
                    item-text="value"
                    :rules="rules.required"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Zona -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-autocomplete
                    v-model="zonaSelected"
                    :items="zonas"
                    label="Zona"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Codigo postal -->
                <v-col cols="12" sm="6" md="2" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    v-mask="'#####'"
                    :rules="rules.validCodigoPostal"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    label="C. Postal"
                    v-model="cPostal"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Teléfono -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    :rules="[rules.maxLength(telefono, 30)]"
                    label="Teléfono"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    v-model="telefono"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Celular -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                    dense
                    hint="Ej. 3519876543"
                    :rules="[rules.maxLength(celular, 50)]"
                    label="Celular"
                    v-model="celular"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <!-- Email -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    :rules="
                      rules.email.concat([rules.maxLength(emailAgenteCta, 200)])
                    "
                    label="Email"
                    v-model="emailAgenteCta"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!-- No bonifica -->
                <v-col cols="12" sm="6" md="5" class="pt-0">
                  <v-switch
                    class="mt-4"
                    v-model="noBonificaOsCheck"
                    label="No aplica bonificación por cantidad de integrantes"
                    item-text="value"
                    item-value="id"
                    dense
                    outlined
                  ></v-switch>
                </v-col>
                <!-- Observaciones -->
                <v-col class="pt-0 py-0" cols="12" sm="12" md="8">
                  <v-textarea
                    v-model="observacionesAgenteCta"
                    auto-grow
                    :rules="[rules.maxLength(observacionesAgenteCta, 250)]"
                    label="Observaciones"
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-col cols="11" class="pt-1 px-10" v-if="!showBackBtn">
                <v-btn outlined @click="goBackTo()" class="to-right">
                  Volver
                </v-btn>
              </v-col>
              <v-col :cols="showBackBtn ? '12' : '1'" align="end" class="pt-1">
                <v-btn
                  color="primary"
                  @click="saveAgenteCta()"
                  class="to-right"
                  :disabled="!isFormValid"
                >
                  Avanzar
                </v-btn>
              </v-col>
              <!--  -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-if="modalDatosAdicionales"
      v-model="modalDatosAdicionales"
      @keydown.esc="closeAndReload"
      max-width="50%"
      persistent
    >
      <DatosAdicionalesAgenteCuenta
        :agenteCta="agenteCta"
        :codigoAgecta="afiliadoCodigo"
        @closeAndReload="closeAndReload"
        @goToReferencias="goToReferencias"
      ></DatosAdicionalesAgenteCuenta>
    </v-dialog>
    <v-dialog
      v-model="modalBusquedaAgecta"
      v-if="modalBusquedaAgecta"
      @keydown.esc="toggleModalBusquedaAgecta"
      max-width="60%"
      persistent
    >
      <BuscarAgenteCuenta
        @toggleModalBusquedaAgecta="toggleModalBusquedaAgecta"
        @findAgenteCta="findAgenteCta"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DatosAdicionalesAgenteCuenta from "@/components/modules/afiliaciones/afiliados/DatosAdicionalesAgenteCuenta.vue";
import validateCUIL from "@/utils/helpers/validateCUIL";
import { mask } from "vue-the-mask";
import axios from "@/axios/axios-api";
import BuscarAgenteCuenta from "../agenteCuenta/BuscarAgenteCuenta.vue";

export default {
  name: "CargaAgentesCuenta",
  directives: { mask },
  components: {
    PageHeader,
    GoBackBtn,
    DatosAdicionalesAgenteCuenta,
    BuscarAgenteCuenta
  },
  props: { agectaId: { type: [String, Number], require: true, default: null } },
  data() {
    return {
      routeToGo: "GestionAgenteCuenta",
      title: "Editar Agente de cuenta",
      enums: enums,
      searchIcon: enums.icons.SEARCH,
      codigoAgenteCta: null,
      buscarAgente: false,
      isFormValid: false,
      rules: rules,
      nombreCompleto: null,
      tiposAgentes: [],
      tipoAgenteSelected: null,
      numeroInterno: null,
      noBonificaOsCheck: false,
      tipoIvaSelected: null,
      tiposIva: [],
      cuit: null,
      tipoDocSelected: null,
      tiposDoc: [],
      docAgenteCta: null,
      jurisdiccionSelected: null,
      jurisdicciones: [],
      ingBrutos: null,
      condicionSelected: null,
      condiciones: [],
      conceptos: [],
      conceptoSelected: null,
      calle: null,
      departamento: null,
      piso: null,
      numDomicilio: null,
      searchAgenteBtn: false,
      barrios: [],
      barrioSelected: null,
      plano: null,
      zonas: [],
      zonaSelected: null,
      localidadSelected: null,
      localidades: [],
      cPostal: null,
      telefono: null,
      fax: null,
      celular: null,
      emailAgenteCta: null,
      observacionesAgenteCta: null,
      cobradorSelected: null,
      cobradores: [],
      sucursales: [],
      sucPagoSelected: null,
      agente: {},
      newAgenteFromAfiliados: false,
      benGrId: null,
      modalDatosAdicionales: false,
      agenteCta: null,
      buscarAgenteForAfiliado: false,
      showBackBtn: true,
      afiliadoCodigo: null,
      modalBusquedaAgecta: false
    };
  },
  created() {
    this.setAgenteCuenta();
    this.setSelects();
    this.ocultarSpinnerLoading();
    if (this.$route.params.benGrId) {
      this.benGrId = this.$route.params.benGrId;
      this.newAgenteFromAfiliados = true;
    }
    if (this.$route.params.asignarAgente) {
      this.buscarAgenteForAfiliado = this.$route.params.asignarAgente;
      this.benGrId = this.$route.params.benGrId;
      this.newAgenteFromAfiliados = true;
    }
    if (this.$route.params.afiliadoId) {
      this.showBackBtn = false;
    }
  },
  watch: {
    codigoAgenteCta() {
      if (
        this.codigoAgenteCta != null &&
        this.codigoAgenteCta != undefined &&
        this.codigoAgenteCta != ""
      ) {
        this.searchAgenteBtn = true;
      } else {
        this.searchAgenteBtn = false;
      }
    }
  },
  methods: {
    toggleModalBusquedaAgecta() {
      this.modalBusquedaAgecta = !this.modalBusquedaAgecta;
    },
    closeAndReload() {
      this.modalDatosAdicionales = false;
    },
    ...mapActions({
      getAgenteCuentaByCodigo: "afiliaciones/getAgenteCuentaByCodigo",
      getCobradores: "afiliaciones/getCobradores",
      getTiposDoc: "afiliaciones/getTiposDoc",
      getBarrios: "afiliaciones/getBarrios",
      getLocalidades: "afiliaciones/getLocalidades",
      getCondicionesIIBB: "afiliaciones/getCondicionesIIBB",
      getConceptosIIBB: "afiliaciones/getConceptosIIBB",
      getZonas: "afiliaciones/getZonas",
      getSucPagos: "afiliaciones/getSucPagos",
      getJurisdiccionesIIBB: "afiliaciones/getJurisdiccionesIIBB",
      getTiposIva: "afiliaciones/getTiposIva",
      getTiposAgenteCta: "afiliaciones/getTiposAgenteCta",
      postAgenteCuenta: "afiliaciones/postAgenteCuenta",
      setAlert: "user/setAlert"
    }),
    setAgenteCuenta() {
      if (this.agectaId !== null || this.$route.params.agectaId) {
        this.codigoAgenteCta =
          this.agectaId !== null ? this.agectaId : this.$route.params.agectaId;
        this.findAgenteCta();
      } else {
        this.title = "Nuevo Agente de cuenta";
      }
    },
    validarCUIL(cuil) {
      if (cuil != null && cuil != "") {
        const res = validateCUIL.validateCUITCUIL(cuil);
        if (res === false) {
          this.setAlert({
            type: "warning",
            message: "El CUIT ingresado es incorrecto."
          });
        }
      }
    },
    ocultarSpinnerLoading() {
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false
      });
    },
    async setSelects() {
      await axios
        .all([
          this.getTiposDoc(),
          this.getCobradores(),
          this.getLocalidades(),
          this.getBarrios(),
          this.getJurisdiccionesIIBB(),
          this.getTiposAgenteCta(),
          this.getCondicionesIIBB(),
          this.getConceptosIIBB(),
          this.getSucPagos(),
          this.getZonas(),
          this.getTiposIva()
        ])
        .then(
          axios.spread((...responses) => {
            this.tiposDoc = responses[0];
            this.cobradores = responses[1];
            this.localidades = responses[2];
            this.barrios = responses[3];
            this.jurisdicciones = responses[4];
            this.tiposAgentes = responses[5];
            this.condiciones = responses[6];
            this.conceptos = responses[7];
            this.sucursales = responses[8];
            this.zonas = responses[9];
            this.tiposIva = responses[10];
          })
        );
    },
    async findAgenteCta(agenteSelected) {
      this.buscarAgente = true;
      this.afiliadoCodigo = this.codigoAgenteCta;
      if (agenteSelected == undefined || agenteSelected == {}) {
        const response = await this.getAgenteCuentaByCodigo({
          codigo: this.codigoAgenteCta,
          nombre: "",
          dni: "",
          cuit: ""
        });
        this.agente = response[0];
      } else {
        this.agente = agenteSelected;
        this.codigoAgenteCta = agenteSelected.agenteId;
        this.afiliadoCodigo = agenteSelected.agenteId;
      }
      this.nombreCompleto = this.agente.nombre;
      this.tipoAgenteSelected = this.agente.tipoAgenteId;
      this.tipoIvaSelected = this.agente.ivaId;
      this.cuit = this.agente.cuit;
      this.ingBrutos = this.agente.agenteIngbru;
      this.calle = this.agente.calle;
      this.numDomicilio = this.agente.numero;
      this.departamento = this.agente.depto;
      this.telefono = this.agente.telefono;
      this.fax = this.agente.agenteFax;
      this.barrioSelected = this.agente.barrioId;
      this.localidadSelected = this.agente.localidadId;
      this.cobradorSelected = this.agente.vendedorId;
      this.sucPagoSelected = this.agente.sucPagoId;
      this.plano = this.agente.plano;
      this.cPostal = this.agente.codPostal;
      this.tipoDocSelected = this.agente.tdId;
      this.docAgenteCta = this.agente.documento;
      this.observacionesAgenteCta = this.agente.agenteObs;
      this.zonaSelected = this.agente.zonId;
      this.noBonificaOsCheck = this.agente.noBonificaOs;
      this.condicionSelected = this.agente.condIbId;
      this.conceptoSelected = this.agente.concIbId;
      this.piso = this.agente.piso;
      this.emailAgenteCta = this.agente.email;
      this.numeroInterno = this.agente.agenteNroInt;
      this.jurisdiccionSelected = this.agente.jurIbId;
      this.celular = this.agente.agenteCelular;
    },
    async saveAgenteCta() {
      const data = this.newAgenteFromAfiliados
        ? {
            agenteId: this.afiliadoCodigo,
            nombre: this.nombreCompleto.toUpperCase(),
            tipoAgenteId: this.tipoAgenteSelected,
            ivaId: this.tipoIvaSelected,
            cuit: this.cuit ? this.cuit.replaceAll("-", "") : this.cuit,
            agenteIngbru: this.ingBrutos,
            calle: this.calle != null ? this.calle.toUpperCase() : "S/D",
            numero: this.numDomicilio != null ? this.numDomicilio : "0",
            depto: this.departamento,
            telefono: this.telefono != null ? this.telefono : "    ",
            agenteFax: this.fax,
            barrioId: this.barrioSelected,
            localidadId: this.localidadSelected,
            vendedorId: this.cobradorSelected,
            plano: this.plano,
            codPostal: this.cPostal != null ? this.cPostal : 0,
            tdId: this.tipoDocSelected,
            documento: this.docAgenteCta
              ? this.docAgenteCta.replaceAll(".", "")
              : this.docAgenteCta,
            agenteObs: this.observacionesAgenteCta,
            zonId: this.zonaSelected,
            noBonificaOs: this.noBonificaOsCheck,
            condIbId: this.condicionSelected,
            concIbId: this.conceptoSelected,
            piso: this.piso,
            email: this.emailAgenteCta,
            agenteNroInt: this.numeroInterno,
            jurIbId: this.jurisdiccionSelected,
            agenteCelular: this.celular,
            benGrId: this.benGrId,
            fechaAlta: new Date().toISOString().substr(0, 10)
          }
        : {
            agenteId: this.afiliadoCodigo,
            nombre: this.nombreCompleto.toUpperCase(),
            tipoAgenteId: this.tipoAgenteSelected,
            cuit: this.cuit ? this.cuit.replaceAll("-", "") : this.cuit,
            agenteIngbru: this.ingBrutos,
            calle: this.calle != null ? this.calle : "S/D",
            numero: this.numDomicilio != null ? this.numDomicilio : 0,
            depto: this.departamento,
            telefono: this.telefono != null ? this.telefono : "  ",
            agenteFax: this.fax,
            barrioId: this.barrioSelected,
            localidadId: this.localidadSelected,
            vendedorId: this.cobradorSelected,
            plano: this.plano,
            codPostal: this.cPostal != null ? this.cPostal : 0,
            tdId: this.tipoDocSelected,
            documento: this.docAgenteCta
              ? this.docAgenteCta.replaceAll(".", "")
              : this.docAgenteCta,
            agenteObs: this.observacionesAgenteCta,
            zonId: this.zonaSelected,
            noBonificaOs: this.noBonificaOsCheck,
            condIbId: this.condicionSelected,
            concIbId: this.conceptoSelected,
            piso: this.piso,
            email: this.emailAgenteCta,
            agenteNroInt: this.numeroInterno,
            jurIbId: this.jurisdiccionSelected,
            agenteCelular: this.celular
          };
      this.agenteCta = data;
      this.modalDatosAdicionales = true;
    },
    goToReferencias() {
      this.$router.push({
        name: "DatosComplementariosAfiliado",
        params: {
          asistenteOn: false,
          benId: this.$route.params.afiliadoId,
          benGrId: this.$route.params.benGrId,
          fechaConsulta: this.$route.params.fechaConsulta,
          openAgenteCtaCard: true,
          canSeeGestionMovimientos: true
        }
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    },
    resetForm() {
      this.$refs["agenteCta-form"].reset();
      this.buscarAgente = false;
    },
    openModalDatosAdicionales() {
      this.modalDatosAdicionales = true;
    },
    goBackTo() {
      this.$router.push({
        name: "DatosComplementariosAfiliado",
        params: {
          asistenteOn: false,
          benId: this.$route.params.afiliadoId,
          fechaConsulta: this.$route.params.fechaConsulta,
          benGrId: this.$route.params.benGrId,
          openAgenteCtaCard: true,
          canSeeGestionMovimientos: this.$route.params.canSeeGestionMovimientos
        }
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
::v-deep label {
  margin-bottom: 0;
}
</style>
