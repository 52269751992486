<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Condición IVA -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="tipoIvaSelected"
                :items="tiposIva"
                label="Condición de IVA (*)"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Cobrador -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="cobradorSelected"
                :items="cobradores"
                label="Cobrador"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Suc. Pago -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="sucPagoSelected"
                :items="sucursales"
                label="Sucursal de pago"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Forma Pago -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="formaPagoSelected"
                :items="formasPagos"
                label="Forma de pago"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Entidad financiera -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="entidadFinancieraSelected"
                :items="entidadesFinancieras"
                label="Entidad financiera"
                item-text="value"
                :disabled="this.formaPagoSelected == null"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- CBU -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :disabled="this.formaPagoSelected == null"
                :rules="[rules.maxLength(cbu, 24)]"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="CBU"
                v-model="cbu"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Número de tarjeta/cuenta -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="[rules.maxLength(numeroCuenta, 30)]"
                :disabled="this.formaPagoSelected == null"
                label="N° (Tarjeta/Cuenta)"
                v-model="numeroCuenta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Vencimiento -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                :disabled="this.formaPagoSelected == null"
                dense
                v-mask="'##/##'"
                onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                label="Vencimiento de tarjeta"
                hint="MM/AA"
                persistent-hint
                v-model="vencimientoTarjeta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-row v-if="requestPeriodosAgente || requestPeriodos">
              <v-col cols="12" md="12" align="start" class="py-0">
                <v-card-title class="primary--text"
                  >Relación grupo familiar-agente de cuenta</v-card-title
                >
              </v-col>
            </v-row>
            <!-- Vigencia Desde -->
            <v-col
              cols="12"
              md="6"
              sm="6"
              class="py-0"
              v-if="requestPeriodosAgente || requestPeriodos"
            >
              <v-text-field
                dense
                outlined
                v-model="vigenciaDesdeAgente"
                label="Vigencia desde (*)"
                hint="Formato AAAAMM"
                type="text"
                persistent-hint
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [
                      rules.validDateRange(
                        vigenciaDesdeAgente,
                        vigenciaHastaAgente
                      )
                    ],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <!-- Vigencia Hasta -->
            <v-col
              cols="12"
              md="6"
              sm="6"
              class="py-0"
              v-if="requestPeriodosAgente || requestPeriodos"
            >
              <v-text-field
                dense
                outlined
                v-model="vigenciaHastaAgente"
                label="Vigencia hasta (*)"
                type="text"
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                persistent-hint
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [
                      rules.validDateRange(
                        vigenciaDesdeAgente,
                        vigenciaHastaAgente
                      )
                    ],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
          @click.stop.prevent="saveEdit()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";

export default {
  name: "EditCreacionAgenteCuenta",
  props: {
    periodoDesde: {
      type: String,
      required: false
    },
    agectaId: {
      type: Number,
      required: false
    },
    nroGrupo: {
      type: [String, Number],
      required: false
    },
    afiliado: {
      type: Object,
      required: false
    },
    domicilioAgente: {
      type: Object,
      required: false
    },
    agenteCta: {
      type: Object,
      required: false
    },
    requestPeriodos: {
      type: Boolean,
      required: false
    },
    codigoAgecta: {
      type: [String, Number],
      required: false
    },
    newAfiliado: {
      type: Boolean,
      required: false
    },
    fromDatosComplementariosAfiliado: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  directives: { mask },
  created() {
    this.setSelects();
    if (this.agectaId != null) {
      this.ageId = this.agectaId;
      this.setDatosAdicionalesAgente(this.ageId);
    }
    if (this.$route.params.agectaId) {
      this.ageId = this.$route.params.agectaId;
      this.setDatosAdicionalesAgente(this.ageId);
    }
    if (this.$route.params.requestPeriodosAgente) {
      this.requestPeriodosAgente = this.$route.params.requestPeriodosAgente;
      if (this.codigoAgecta != null)
        this.setDatosAdicionalesAgente(this.codigoAgecta);
    }
  },
  data: vm => ({
    title: "Datos adicionales para crear el Agente de cuenta",
    tipoIvaSelected: null,
    tiposIva: [],
    ingBrutos: null,
    requestPeriodosAgente: false,
    vigenciaDesdeAgente: vm.formatDateToPeriodo(
      new Date().toISOString().substr(0, 10)
    ),
    vigenciaHastaAgente: "999912",
    ageId: null,
    cobradorSelected: null,
    cobradores: [],
    sucPagoSelected: null,
    sucursales: [],
    newAgenteId: null,
    formaPagoSelected: null,
    formasPagos: [],
    entidadFinancieraSelected: null,
    entidadesFinancieras: [],
    rules: rules,
    numeroCuenta: null,
    cbu: null,
    isFormValid: false,
    vencimientoTarjeta: null,
    canEditDatos: true,
    movFPagoId: 0,
    periodoMovDesde: null,
    periodoMovHasta: null,
    benefAgectaId: 0
  }),
  methods: {
    ...mapActions({
      getCobradores: "afiliaciones/getCobradores",
      postAgenteCuenta: "afiliaciones/postAgenteCuenta",
      getSucPagos: "afiliaciones/getSucPagos",
      getTiposIva: "afiliaciones/getTiposIva",
      postDatosAdicionalesAgenteCuenta:
        "afiliaciones/postDatosAdicionalesAgenteCuenta",
      saveBenefAgeCta: "afiliaciones/saveBenefAgeCta",
      getEntidadesFinancieras: "devengamientos/getEntidadesFinancieras",
      getFormasDePago: "devengamientos/getFormasDePago",
      getDatosAdicionalesAgenteBenef:
        "agenteCuenta/getDatosAdicionalesAgenteBenef",
      getDatosAdicionalesAgenteCuenta:
        "agenteCuenta/getDatosAdicionalesAgenteCuenta",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const cobradores = await this.getCobradores();
      this.cobradores = cobradores;
      const sucursales = await this.getSucPagos();
      this.sucursales = sucursales;
      if (this.sucursales.length === 1) {
        this.sucPagoSelected = this.sucursales[0].id;
      }
      const tiposIva = await this.getTiposIva();
      this.tiposIva = tiposIva;
      const entidadesFinancieras = await this.getEntidadesFinancieras();
      this.entidadesFinancieras = entidadesFinancieras;
      const formasPagos = await this.getFormasDePago();
      this.formasPagos = formasPagos;
    },
    async saveEdit() {
      const vencimiento = this.vencimientoTarjeta
        ? this.vencimientoTarjeta.split("/")
        : null;
      const data =
        this.agenteCta === null || this.agenteCta === undefined
          ? {
              agenteId: this.ageId,
              nombre: `${this.afiliado.apellido.trim()} ${
                this.afiliado.nombre != null ? this.afiliado.nombre.trim() : ""
              }`,
              tipoAgenteId: 1,
              cuit: this.afiliado.cUIL,
              calle: this.domicilioAgente.calle,
              numero: this.domicilioAgente.numero,
              depto: this.domicilioAgente.depto,
              telefono: this.domicilioAgente.telParticular,
              barrioId: this.domicilioAgente.barrioId,
              localidadId: this.domicilioAgente.localidadId,
              codPostal: this.domicilioAgente.codigoPostal,
              tdId: this.afiliado.tipoDoc,
              documento: this.afiliado.dNI,
              piso: this.domicilioAgente.piso,
              email: this.afiliado.email,
              agenteCelular: this.domicilioAgente.telParticular,
              ivaId: this.tipoIvaSelected,
              vendedorId: this.cobradorSelected,
              periodoDesdeAgente: null,
              periodoHastaAgente: null,
              sucPagoId:
                this.sucPagoSelected != null ? this.sucPagoSelected : 0,
              formaPagoId: this.formaPagoSelected,
              entFinId: this.entidadFinancieraSelected,
              cbu: this.cbu,
              ageId: this.ageId,
              benefAgectaId: this.benefAgectaId,
              numeroCuenta: this.numeroCuenta,
              movFPagoId: this.movFPagoId,
              mesVencimiento: vencimiento != null ? vencimiento[0] : null,
              benGrId:
                this.nroGrupo != null
                  ? this.nroGrupo
                  : this.$route.params.benGrId,
              fechaAlta:
                this.periodoDesde !== null
                  ? this.periodoDesde
                  : this.$route.params.periodoDesde,
              añoVencimiento: vencimiento != null ? vencimiento[1] : null,
            }
          : {
              agenteId:
                this.agenteCta.agenteId != null
                  ? this.agenteCta.agenteId
                  : this.ageId,
              nombre: this.agenteCta.nombre,
              tipoAgenteId: this.agenteCta.tipoAgenteId,
              cuit: this.agenteCta.cuit,
              agenteIngbru: this.agenteCta.agenteIngbru,
              calle: this.agenteCta.calle,
              numero: this.agenteCta.numero,
              depto: this.agenteCta.depto,
              telefono: this.agenteCta.telefono,
              barrioId: this.agenteCta.barrioId,
              localidadId: this.agenteCta.localidadId,
              plano: this.agenteCta.plano,
              codPostal: this.agenteCta.codPostal,
              tdId: this.agenteCta.tdId,
              documento: this.agenteCta.documento,
              agenteObs: this.agenteCta.agenteObs,
              zonId: this.agenteCta.zonId,
              noBonificaOs: this.agenteCta.noBonificaOs,
              condIbId: this.agenteCta.condIbId,
              concIbId: this.agenteCta.concIbId,
              piso: this.agenteCta.piso,
              email: this.agenteCta.email,
              agenteCelular: this.agenteCta.agenteCelular,
              agenteNroInt: this.agenteCta.agenteNroInt,
              jurIbId: this.agenteCta.jurIbId,
              ivaId: this.tipoIvaSelected,
              fromAfiliado: this.fromDatosComplementariosAfiliado,
              ageId: this.ageId,
              vendedorId: this.cobradorSelected,
              sucPagoId:
                this.sucPagoSelected != null ? this.sucPagoSelected : 0,
              formaPagoId: this.formaPagoSelected,
              entFinId:
                this.formaPagoSelected != null
                  ? this.entidadFinancieraSelected
                  : 0,
              cbu: this.formaPagoSelected != null ? this.cbu : "    ",
              periodoDesdeAgente: this.vigenciaDesdeAgente,
              periodoHastaAgente: this.vigenciaHastaAgente,
              numeroCuenta:
                this.formaPagoSelected != null ? this.numeroCuenta : "    ",
              benGrId: this.$route.params.benGrId
                ? this.$route.params.benGrId
                : this.agenteCta.benGrId,
              fechaAlta: this.agenteCta.fechaAlta,
              movFPagoId: this.movFPagoId,
              movFPHasta: this.periodoMovHasta,
              benefAgectaId: this.benefAgectaId,
              movFPDesde: this.periodoMovDesde,
              mesVencimiento: vencimiento != null ? vencimiento[0] : null,
              añoVencimiento: vencimiento != null ? vencimiento[1] : null
            };
      const response = await this.postAgenteCuenta(data);
      if (response.status === 200) {
        this.setAlert({
          type: "success",
          message: "Guardado con éxito"
        });
        if (
          this.requestPeriodos === true ||
          this.requestPeriodosAgente === true ||
          this.newAfiliado
        ) {
          this.$emit("goToReferencias");
        } else {
          this.newAgenteId = response.data.data;
          this.goToGestionAgeCta();
        }
        this.$emit("closeAndReload");
        this.$emit("nextQuestion");
      }
    },
    goToGestionAgeCta() {
      if (this.ageId == null) {
        const datosAgente = {
          nombre: this.agenteCta.nombre,
          id: this.newAgenteId
        };
        this.$router.push({
          name: "GestionAgenteCuenta",
          params: { nuevoAgente: true, datosAgente: datosAgente }
        });
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true
        });
      } else {
        this.$router.push({
          name: "GestionAgenteCuenta",
          params: {
            editAgente: true
          }
        });
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true
        });
      }
    },
    formatDateToPeriodo(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${year}${month}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async setDatosAdicionalesAgente(agectaId) {
      const fechaActual = new Date().toISOString().substr(0, 10);
      let response = null;
      if (this.$route.params.benGrId && this.$route.params.fechaConsulta) {
        response = await this.getDatosAdicionalesAgenteBenef({
          id: agectaId,
          benGrId: this.$route.params.benGrId,
          fechaConsulta: this.$route.params.fechaConsulta
        });
      } else {
        response = await this.getDatosAdicionalesAgenteCuenta({
          id: agectaId,
          fechaConsulta: fechaActual
        });
      }
      this.sucPagoSelected = response.sucPagoId;
      this.vencimientoTarjeta = response.vencimiento;
      this.tipoIvaSelected = response.ivaId;
      this.cobradorSelected = response.vendedorId;
      this.formaPagoSelected = response.formaPagoId;
      this.entidadFinancieraSelected = response.entFinId;
      this.numeroCuenta = response.numeroCuenta;
      this.cbu = response.cbu;
      this.movFPagoId = response.movFPagoId != null ? response.movFPagoId : 0;
      this.vigenciaDesdeAgente = response.periodoDesdeAgente;
      this.vigenciaHastaAgente = response.periodoHastaAgente;
      this.periodoMovDesde = response.movFPDesde;
      this.periodoMovHasta = response.movFPHasta;
      this.benefAgectaId =
        response.benefAgectaId != null ? response.benefAgectaId : 0;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
